ul,
nav {
  list-style: none;
}
a {
  text-decoration: none;
}
.dashbaord_header {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  align-items: flex-start;
  background-color: #faf9f9;
  height: 60px;
  position: relative;
  z-index: 99;
  position: sticky;
  top: 0px;
}
.dashbaord_header .dropdown_first {
  position: absolute;
  top: 100%;
  padding: 0rem 1.5rem;
  left: 0px;
  background: rgba(255, 255, 255, 0.69);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.8px);
  -webkit-backdrop-filter: blur(9.8px);
  border: 1px solid rgba(255, 255, 255, 0.45);
  display: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.dashbaord_header .dropdown_first#active {
  display: block;
}
.dashbaord_header .dropdown_first > li {
  padding: 0.9rem 0rem;
  border-bottom: 1px solid #dddddd;
  font-size: 20px;
  position: relative;
}
.dashbaord_header .dropdown_first > li p {
  cursor: pointer;
}
.first_dropdown_inner {
  position: absolute;
  left: 110%;
  top: 0;
  padding: 0rem 1rem;
  width: 180px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 13px;
  display: none;
}
.first_dropdown_inner#active {
  display: block;
}
.first_dropdown_inner li {
  padding: 0.6rem 0rem;
}
.dashbaord_header .right_wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  padding: 0rem 1rem;
}

.dashbaord_header .menu_img {
  height: 100%;
  cursor: pointer;
}

.dashbaord_header .logo_img {
  width: 200px;
}
.dashbaord_header .bulb_img {
  width: 30px;
  margin-right: 20px;
  cursor: pointer;
}
.dropdown_bulb {
  position: absolute;
  right: 0px;
  top: 135%;
  width: 450px;
  background: rgba(255, 255, 255, 0.69);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.8px);
  -webkit-backdrop-filter: blur(9.8px);
  border: 1px solid rgba(255, 255, 255, 0.45);
  padding: 0.9rem;
  border-radius: 13px;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.dropdown_bulb#active {
  display: block;
}
.dropdown_bulb .body_area {
  padding: 0px;
}
.dropdown_bulb .body_area .box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d9d9;
}
.dropdown_bulb .body_area .box {
  margin-top: 0px;
  padding: 1rem 0rem;
}
.dropdown_bulb .body_area .box img {
  width: 70px;
  margin-right: 10px;
}
.dropdown_bulb .body_area .box p {
  line-height: 25px;
  font-size: 18px;
}
.dropdown_bulb .body_area .link_wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
.dropdown_bulb .body_area .link_wrapper a {
  font-size: 20px;
  font-weight: 600;
  color: #545a5a;
  border-bottom: 2px solid #ff5634;
  transition: 0.3s ease all;
}
.dropdown_bulb .body_area .link_wrapper a:hover {
  padding-bottom: 5px;
}
.dropdown_bulb .head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.dropdown_bulb .head p {
  font-size: 20px;
}
.dropdown_bulb .head span {
  background: linear-gradient(45deg, #ffaa25, #ff5634);
  font-size: 12px;
  color: #fff;
  padding: 0.2rem 1rem;
  border-radius: 99px;
}
.dashbaord_header .right_wrapper nav li {
  position: relative;
}
.dashbaord_header .right_wrapper nav,
.dashbaord_header .profile_img_wrapper {
  display: flex;
  align-items: center;
}
.dashbaord_header .profile_img_wrapper {
  position: relative;
}
.dashbaord_header .profile_dropdown {
  position: absolute;
  top: 130%;
  right: 0px;
  width: 250px;
  background: rgba(255, 255, 255, 0.69);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.8px);
  -webkit-backdrop-filter: blur(9.8px);
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 13px;
  display: none;
  padding: 0rem 1rem;
}
.dashbaord_header .profile_dropdown#active {
  display: block;
}
.dashbaord_header .middle_area {
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
}
.dashbaord_header .middle_area p {
  font-size: 18px;
  color: #b7b9b9;
  font-weight: 300;
}
.dashbaord_header .middle_area .dropdown {
  background-color: #fff;
  margin-left: 20px;
  position: relative;
}
.dashbaord_header .middle_area .dropdown ul {
  position: absolute;
  z-index: 99;
  width: 100%;
  display: none;
}
.dashbaord_header .middle_area .dropdown ul.active {
  display: block;
}
.dashbaord_header .middle_area .dropdown ul li {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 0.8rem 1rem;
}
.dashbaord_header .middle_area .dropdown ul li img {
  width: 100px;
}
.dashbaord_header .middle_area .dropdown ul li:nth-child(4) img {
  width: 90px;
}
.dashbaord_header .middle_area .dropdown .head {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 43px;
  width: 300px;
  border: 1px solid #ddd;
  padding: 0rem 1rem;
}
.dashbaord_header .middle_area .dropdown .head > *:last-child {
  cursor: pointer;
}
.dashbaord_header .middle_area .dropdown .head img {
  width: 120px;
}
.dashbaord_header .middle_area .buttons_wrapper {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
.dashbaord_header .middle_area .buttons_wrapper button {
  background: linear-gradient(45deg, #ffaa25, #ff5634);
  color: #fff;
  height: 43px;
  border-radius: 13px;
  border: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
  padding: 0rem 1rem;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
.dashbaord_header .middle_area .buttons_wrapper > * + * {
  margin-left: 10px;
}
.dashbaord_header .profile_dropdown li {
  padding: 0.5rem 0rem;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.dashbaord_header .profile_dropdown li:nth-child(2) {
  padding-bottom: 1rem;
}
.dashbaord_header .profile_dropdown li:last-child {
  padding-top: 0.6rem;
  border-top: 1px solid #d8d9d9;
}
.dashbaord_header .profile_dropdown li img {
  width: 40px;
}
.dashbaord_header .profile_img_wrapper {
  cursor: pointer;
}
.dashbaord_header .profile_img_wrapper > * + * {
  margin-left: 10px;
}
.dashbaord_header .profile_img_wrapper > *:last-child {
  margin-left: 5px;
}
.dashbaord_header .profile_img_wrapper p {
  font-size: 15px;
}
.dashbaord_header .profile_img {
  height: 35px;
  width: 35px;

  object-fit: cover;
  border-radius: 50%;
}
.Empujessection1 {
  padding-bottom: 1.5rem;
}
.Empujessection2 {
  border-top: 1px solid #ddd;
  padding-top: 2rem;
  max-height: 350px;
  overflow-x: scroll;
  overflow-y: scroll;
}
.popup_wrapper {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup_wrapper .popup {
  background-color: #fff;
  padding: 2rem;
  position: relative;
  border-radius: 5px;
}

.popup_wrapper .SelectionLogoPopUp {
  width: 1000px;
  background-color: #eceded !important;
  padding: 4rem;
  border-radius: 20px;
}
.popup_wrapper .SelectionLogoPopUp h1 {
  font-size: 30px;
  font-weight: 600;
}
.popup_wrapper .popup .logo_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 4rem;
}
.popup_wrapper .popup .logo_wrapper li {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.popup_wrapper .popup .logo_wrapper li a {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.popup_wrapper .popup .logo_wrapper img {
  width: 80%;
}
.popup_wrapper .popup .section > h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.popup_wrapper .popup.Empujes {
  width: 1050px;
}
.popup_wrapper .popup .close_icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.popup_wrapper .empujes_row {
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr 0.5fr 1fr 1fr 50px;
  grid-gap: 10px;
}

.popup_wrapper .empujes_row label {
  font-size: 16px;
  font-weight: 600;
}
.popup_wrapper .empujes_row input,
.popup_wrapper .empujes_row select {
  display: block;
  height: 30px;
  padding: 0rem 10px;
  width: 100%;
  border: 1px solid rgb(223, 218, 218);
  margin-top: 10px;
  outline: none;
}
.popup_wrapper .empujes_row input {
  border-top: none;
  border-left: none;
  border-right: none;
}

.popup_wrapper .Empujes_top_link_wrapper,
.Empujes_bottom_link_wrapper,
.EmpujesMassive .button_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.Empujes_bottom_link_wrapper,
.EmpujesMassive .button_wrapper {
  justify-content: flex-end;
  margin-top: 3rem;
}
.Empujes_bottom_link_wrapper {
  align-items: center;
}
.Empujes_bottom_link_wrapper > * + * {
  margin-left: 2rem;
}
.popup_wrapper .Empujes_top_link_wrapper a,
.Empujes_bottom_link_wrapper a,
.EmpujesMassive .bottom_wrapper a {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #ff5634;
  padding-bottom: 5px;
  transition: 0.3s ease all;
}
.Empujes_bottom_link_wrapper a.fill,
.EmpujesMassive .button_wrapper a {
  background: linear-gradient(45deg, #ffaa25, #ff5634);
  color: #fff;
  text-decoration: none;
  height: 50px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.popup_wrapper .Empujes_top_link_wrapper a:hover {
  transform: scale(1.05);
}
.popup_wrapper .empujes_row .date_wrapper,
.date_wrapper {
  display: flex;
  align-items: center;
  align-items: center;
  border: 1px solid rgb(223, 218, 218);
  height: 30px;
  position: relative;
  padding: 0rem 10px;
  margin-top: 10px;
}
.popup_wrapper .empujes_row .date_wrapper input,
.date_wrapper input {
  border: none;
  margin-top: 0px;
  outline: none;
  height: 25px;
}
.date_wrapper input {
  margin-left: 10px;

  flex: 1;
  padding-right: 5px;
}
.popup_wrapper .empujes_row .date_wrapper i,
.date_wrapper i {
  cursor: pointer;
  position: absolute;
  right: 20px;
  pointer-events: none;
}
.popup_wrapper .empujes_row .img_wrapper {
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  height: 30px;
  justify-content: center;
}
.popup_wrapper .empujes_row .img_wrapper > img {
  width: 30px;
  cursor: pointer;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

/* EmpujesMassive */
.EmpujesMassive {
  width: 500px;
}
.EmpujesMassive > h1 {
  font-size: 20px;
}
.EmpujesMassive .list_wrapper {
  margin-top: 1rem;
}
.EmpujesMassive .list_wrapper ol {
  padding-left: 1rem;
  margin-top: 1rem;
}
.EmpujesMassive .list_wrapper h1 {
  font-weight: 500;
  margin-top: 3rem;
}
.EmpujesMassive .list_wrapper li,
.EmpujesMassive .list_wrapper h1 {
  font-size: 16px;
}
.EmpujesMassive .list_wrapper ol > * + * {
  margin-top: 10px;
}
.EmpujesMassive .bottom_wrapper {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  align-items: flex-end;
  grid-gap: 10px;
  margin-top: 2rem;
}

.EmpujesMassive .bottom_wrapper .select_wrapper {
  display: flex;
  flex-direction: column;
}

.EmpujesMassive .bottom_wrapper .select_wrapper label {
  font-size: 16px;
}
.EmpujesMassive .bottom_wrapper .select_wrapper select {
  width: 50%;
  margin-top: 10px;
}
.EmpujesMassive .bottom_wrapper a {
  font-size: 20px;
  font-weight: 600;
}
.EmpujesMassive .button_wrapper {
  margin-bottom: 0px;
}
.EmpujesMassive .button_wrapper a {
  font-size: 20px;
  font-weight: 600;

  padding: 0rem 2rem;
  height: 45px;
}
.done {
  width: 400px;
}
.done .body_area {
  display: flex;
  align-items: center;
}
.done .body_area img {
  width: 50px;
}
.done .body_area p {
  margin-left: 2rem;
  font-size: 16px;
  font-weight: 600;
  width: 70%;
}

.leftSearchbar {
  position: fixed;
  padding: 0rem 1rem;
  left: 0px;
  z-index: 99;
  top: 100px;
  height: 93vh;
  bottom: 0px;
  width: 300px;
  padding-top: 2rem;
  background: rgba(255, 255, 255, 0.69);
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(9.8px);
  -webkit-backdrop-filter: blur(9.8px);
  border: 1px solid rgba(255, 255, 255, 0.45);
  transition: 0.4s ease all;
  left: -100%;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px;
}
.leftSearchbarWrapper {
  position: fixed;
  height: 100vh;
  width: 360px;
  z-index: 99;
  left: -18%;
  transition: 0.4s ease all;
}
.leftSearchbarWrapper#active {
  left: 0px;
}
.leftSearchbarWrapper#active .leftSearchbar {
  left: 0px;
}
.leftSearchbar .date_wrapper {
  margin-bottom: 2rem;
}
.leftSearchbarWrapper .leftSearchbarIcon {
  cursor: pointer;
  position: fixed;/*absolute*/
  top: 50%;
  transform: translateY(-50%);
  /*right: 0;*/
  left: 0;
  z-index: 10;
}

.leftSearchbar .selection_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.leftSearchbar .selection_wrapper img {
  width: 25px;
  margin-right: 10px;
}
.leftSearchbar ul li {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  border-bottom: 1px solid #b7b9b8;
}
.leftSearchbar ul li div {
  display: flex;
  align-items: center;
}
.leftSearchbar ul li p {
  flex: 1;
}
.leftSearchbar ul li img {
  width: 30px;
}

.search_input_wrapper {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: transparent;
  border: 1px solid #b5b5b5;
  margin-bottom: 2rem;
  padding: 0rem 0.5rem;
  border-radius: 10px;
}

.search_input_wrapper img {
  width: 30px;
}
.search_input_wrapper input {
  height: 80%;
  outline: none;
  margin-left: 10px;
  flex: 1;
  background: transparent;
  border: none;
}
