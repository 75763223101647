@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body,
select,
input {
  font-family: "Montserrat", sans-serif;
}
