.CardProduction .head_wrapper {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.ProductSelection {
  min-height: 100vh;
  background: url(../../assets/img/Background.png);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProductSelection .cards_wrapper {
  width: 80%;
  margin: 0rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
}
.ProductSelection .cards_wrapper > * {
  background: rgba(255, 255, 255, 0.69);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.8px);
  -webkit-backdrop-filter: blur(9.8px);
  border: 1px solid rgba(255, 255, 255, 0.45);
}
.ProductSelection .cards_wrapper .head_wrapper {
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ProductSelection .cards_wrapper .head_wrapper::after {
  content: "";
  width: 80%;
  position: absolute;
  bottom: 0px;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #cccdcd;
}
.ProductSelection .cards_wrapper .head_wrapper img {
  width: 90%;
}
.ProductSelection .cards_wrapper > * {
  display: flex;
  flex-direction: column;
}
.ProductSelection .cards_wrapper .body_wrapper {
  padding: 2rem;
  flex: 1;
}
.ProductSelection .cards_wrapper .long_card {
  flex-direction: row;
  text-align: left;
}
.ProductSelection .cards_wrapper .body_wrapper p,
.ProductSelection .cards_wrapper .long_card p {
  text-align: center;
  color: #3c3a3b;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.ProductSelection .cards_wrapper .long_card p {
  text-align: left;
}
.ProductSelection .cards_wrapper .footer {
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
}
.ProductSelection .cards_wrapper .footer button,
.ProductSelection .cards_wrapper .long_card button {
  background: linear-gradient(45deg, #ffaa25, #ff5634);
  border: none;
  outline: none;
  padding: 0.8rem 2.5rem;
  border-radius: 10px;
  color: #fff;

  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  font-size: 22px;
  transition: 0.3s ease all;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ProductSelection .cards_wrapper .long_card button {
  width: 300px !important;
}
.ProductSelection .cards_wrapper .long_card button:hover {
  font-size: 23px;
}
.ProductSelection .cards_wrapper button:hover {
  font-size: 23px;
}
.ProductSelection .cards_wrapper .footer#clicked button {
  color: #aeb0af;
  background: transparent;
  pointer-events: none;
  border: 1px solid #aeb0af;
}

.ProductSelection .cards_wrapper .long_card {
  grid-column: span 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem;
}
.ProductSelection .cards_wrapper .long_card h1 {
  font-weight: 600;

  font-size: 28px;
  margin-bottom: 10px;
}
.ProductSelection .cards_wrapper .long_card p {
  color: #3c3a3c;
}
@media screen and (max-width: 1200px) {
  .ProductSelection .cards_wrapper .body_wrapper {
    height: 220px;
  }
}
@media screen and (max-width: 1120px) {
  .ProductSelection .cards_wrapper {
    grid-gap: 1rem;
  }
}
@media screen and (max-width: 1150px) {
  .ProductSelection {
    height: 100%;
    min-height: 100vh;
    padding: 3rem 0rem;
  }
  .ProductSelection .cards_wrapper .long_card button {
    width: unset !important;
  }
  .ProductSelection .cards_wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .ProductSelection .cards_wrapper .long_card {
    grid-column: span 1;
  }
  .ProductSelection .cards_wrapper .long_card {
    flex-direction: column;

    text-align: center;
    justify-content: center;
  }
  .ProductSelection .cards_wrapper .long_card button {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .ProductSelection .cards_wrapper {
    grid-template-columns: 1fr;
  }
  .ProductSelection .cards_wrapper .long_card p {
    text-align: center;
  }
  .ProductSelection .cards_wrapper .long_card {
    grid-column: span 1;
  }
  .ProductSelection .cards_wrapper .body_wrapper {
    height: unset;
  }
  .ProductSelection .cards_wrapper .head_wrapper img {
    width: 200px;
  }
}
@media screen and (max-width: 430px) {
  .ProductSelection .cards_wrapper {
    width: 90%;
  }
  .ProductSelection .cards_wrapper .footer button,
  .ProductSelection .cards_wrapper .long_card button,
  .ProductSelection .cards_wrapper .body_wrapper p,
  .ProductSelection .cards_wrapper .long_card p {
    font-size: 13px;
  }
  .ProductSelection .cards_wrapper .long_card h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1660px) {
  /* .ProductSelection .cards_wrapper .long_card h1 {
    font-weight: 500;

    font-size: 25px;
  }
  .ProductSelection .cards_wrapper .body_wrapper p,
  .ProductSelection .cards_wrapper .long_card p {
    font-size: 15px;
    font-weight: 500;
  }
  .ProductSelection .cards_wrapper .footer button,
  .ProductSelection .cards_wrapper .long_card button {
    font-size: 16px;
  } */
}
