.DemandConnectHome .body_area {
  padding: 3rem;
}
.DemandConnectHome .body_area > * + * {
  margin-top: 2rem;
}
.DemandConnectHome .body_area .ChainSelection,
.LastNew {
  height: 320px;
}
.DemandConnectHome .body_area .ChainSelection,
.LastNew {
  background-color: #fff;
  padding: 2rem;
  border-radius: 13px;
  border: 5px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.DemandConnectHome .body_area .ChainSelection h1,
.LastNew > .head > h1 {
  font-size: 25px;
  font-weight: 500;
}
.LastNew > .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.LastNew > .head .arrows_area {
  color: #acb0b0;
}
.LastNew > .head .arrows_area > * {
  cursor: pointer;
}
.LastNew > .head .arrows_area > * + * {
  margin-left: 10px;
}
.DemandConnectHome .body_area .ChainSelection {
  display: flex;
  flex-direction: column;
}
.DemandConnectHome .body_area .ChainSelection .logos_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 25px;

  flex: 1;
}
.DemandConnectHome .body_area .ChainSelection .logos_wrapper img {
  width: 80%;
}
.DemandConnectHome .body_area .ChainSelection .logos_wrapper .logo_img a {
  display: flex;
  justify-content: center;
}
.DemandConnectHome .body_area .ChainSelection .logos_wrapper .logo_img {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcfcfc;
  height: 97px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.LastNew {
  display: flex;
  flex-direction: column;
}
.LastNew .new_card_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  flex: 1;
  grid-gap: 1rem;
  margin-top: 2rem;
}
.LastNew .new_card_wrapper .NewsCard {
  display: grid;
  grid-template-columns: 130px 1fr;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-top-right-radius: 13px;
  height: 130px;
  border-bottom-right-radius: 13px;
}
.LastNew .new_card_wrapper .NewsCard > img {
  width: 100%;
  height: 100%;
}
.LastNew .new_card_wrapper .NewsCard .right_area {
  padding: 1rem;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.LastNew .new_card_wrapper .NewsCard .right_area .presentation_wrapper {
  flex: 1;
}
.LastNew .new_card_wrapper .NewsCard .right_area .presentation_wrapper p {
  font-size: 14px;
}
.LastNew .new_card_wrapper .NewsCard .right_area .footer_area {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
  border-top: 1px solid #ddd;
}
.LastNew .new_card_wrapper .NewsCard .right_area .footer_area > img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
}
.LastNew .new_card_wrapper .NewsCard .right_area .footer_area > * + * {
  margin-left: 10px;
}
.LastNew .new_card_wrapper .NewsCard .right_area .footer_area p {
  font-size: 12px;
  color: #aeafb1;
}

/* InformationSection */
.InformationSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.InformationSection > img {
  width: 200px;
}
.InformationSection .cards_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
}
.InformationSection .cards_wrapper > * {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 13px;
}
.InformationSection .cards_wrapper > * img {
  width: 40px;
}
.InformationSection .cards_wrapper > * p {
  width: 130px;
}
.InformationSection .cards_wrapper > * > * + * {
  margin-left: 10px;
}
.InformationSection .cards_wrapper button {
  background: linear-gradient(45deg, #ffaa25, #ff5634);
  border: none;
  outline: none;
  padding: 0.5rem 1.5rem;
  border-radius: 13px;
  color: #fff;

  font-weight: 600;
  cursor: pointer;
  font-size: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
