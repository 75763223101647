body {
  background-color: #f6f7f7;
}
.FamiliaChartAreaWrapper {
  width: 95%;
  grid-template-columns: 1fr 0px;/*300px*/
  grid-gap: 2rem;
  display: grid;
  margin: 0rem auto;
  margin-top: 2rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  transition: 0.3s ease all;
}
.FamiliaChartAreaWrapper + div {
  transition: 0.3s ease all;
}
.FamiliaChartAreaWrapper#hide + div {
  transform: translateY(-480px);
}
.FamiliaChartAreaWrapper#hide {
  /* height: 0px; */
  overflow: hidden;
  transform: translateY(-470px);
}
.FamiliaChartAreaWrapper > img:first-child {
  position: absolute;
  right: 20px;
  bottom: -15px;
  cursor: pointer;
}
.FamiliaChartAreaWrapper#hide *:not(img) {
  /* opacity: 0; */
}


.FamiliaChartAreaWrapper .select_container .select_wrapper {
  background-color: #fafafa;
  padding: 0.5rem;
  border: 1px solid #ddd;
}
.FamiliaChartAreaWrapper .select_container > * + * {
  margin-top: 0.5rem;
}
.FamiliaChartAreaWrapper .select_container select {
  width: 100%;
  height: 40px;
  padding-left: 0.5rem;
  font-size: 14px;
  margin-top: 10px;
}

.FamiliaChartArea > h1 {
  text-align: center;
  font-size: 18px;
}
.apexcharts-toolbar {
  display: none !important;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center {
  display: none;
}
.FamiliaChartArea .bottom_area > .chart_area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
}
.FamiliaChartArea .bottom_area > .chart_area p {
  border-bottom: 1px solid #363636;
  padding-bottom: 1rem;
}
.FamiliaChartArea .bottom_area > .chart_area > *:first-child {
  border-right: 1px solid #363636;
}

.FamiliaChartArea .bottom_area .pure_left_chart {
  text-align: center;
  color: #acaab4;
}
.table_area {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  margin-top: 3rem;
}
.FamiliaRowBody {
  background-color: #fff;
  margin-top: 1rem;
  padding: 0rem 1rem;
}
.FamiliaRowBody .head,
.FamiliaRowBody .head .left_side,
.FamiliaMainHead {
  display: flex;
  align-items: center;
}

.FamiliaRowBody .head {
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0rem;
}
.FamiliaMainHead {
  padding: 0.5rem 1rem;
  background: #fff;
  margin-top: 1rem;
}
.FamiliaRowBody .head .left_side img {
  width: 30px;
}
.FamiliaRowBody .head .left_side h1 {
  font-size: 16px;
  margin-left: 0.5rem;
  color: #aeafb1;
  font-weight: 500;
}
.FamiliaRowBody .head .left_side:hover h1 {
  color: #000;
  font-weight: 600;
}
.FamiliaRowBody .bolon_wrapper {
  position: relative;
}
.FamiliaRowBody .bolon_wrapper > img {
  cursor: pointer;
}
.FamiliaRowBody .bolan_catergory_wrapper:hover > p {
  color: #000;
  font-weight: 600;
}
.FamiliaRowBody .bolon_wrapper ul {
  position: absolute;
  right: 0px;
  top: 100%;
  width: 250px;
  z-index: 99;
  background-color: #fff;
  border: 1px solid #ddd;
  text-align: right;
  padding: 1rem;
  display: none;
  cursor: pointer;
}
.FamiliaRowBody .bolon_wrapper ul.active {
  display: block;
}
.FamiliaRowBody .bolon_wrapper ul li {
  font-size: 20px;
  font-weight: 500;
}
.FamiliaRowBody .bolon_wrapper ul > * + * {
  margin-top: 1rem;
}
.FamiliaRowBody .body,
.FamiliaMainHead .head_box .bottom {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
}

.FamiliaRowBody .body .box,
.FamiliaMainHead > * {
  padding: 0rem 1rem;
  border-right: 1px solid #ddd;
}

.FamiliaRowBody .body > *:nth-child(1),
.FamiliaMainHead > :nth-child(1) {
  padding-left: 0px;
  text-align: center;
  padding: 0rem 0rem;
  min-width: 120px;
}

.FamiliaRowBody .body .box .first_td {
  background-color: #fce02c;
  color: #000;
  padding: 0.2rem 1rem;
  border-radius: 99px;
  font-weight: 600;
}
.first_td_img {
  width: 50px;
}
.FamiliaMainHead > :nth-child(1) {
  background-color: transparent;
  border-radius: 0px;
}
.FamiliaRowBody .body .box:nth-child(2),
.FamiliaMainHead > :nth-child(2) {
  min-width: 190px;
  display: flex;
  justify-content: center;
  padding: 0rem 0rem;
  text-align: center;
}
.FamiliaMainHead > :nth-child(2) {
  display: flex;
  justify-content: center;
}
.FamiliaMainHead > :nth-child(2) select {
  width: 80%;
  height: 30px;
  border: 1px solid #ddd;
  font-size: 16px;
  padding-left: 10px;
  outline: none;
}
.FamiliaRowBody .body .box:nth-child(2) p {
  padding: 0.2rem 1rem;
  font-weight: 600;
  border: 2px solid #9febf7;
  border-radius: 99px;
}
.FamiliaRowBody .body .long_box,
.FamiliaMainHead > *:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FamiliaMainHead > *:nth-child(3) {
  flex-direction: column;
  align-items: flex-start;

  min-width: 45%;
}
.FamiliaRowBody .body .box p,
.FamiliaMainHead > *:nth-child(3) p,
.FamiliaMainHead > :nth-child(4) p,
.FamiliaMainHead > *:nth-child(1) p,
.FamiliaMainHead > *:last-child p {
  font-weight: 600;
  font-size: 16px;
}

.FamiliaMainHead > *:nth-child(3) .top p,
.FamiliaMainHead > *:nth-child(4) .top p {
  font-size: 18px;
  font-weight: 500;
}
.FamiliaMainHead > *:nth-child(3) .top p span,
.FamiliaMainHead > *:nth-child(4) .top p span {
  font-size: 16px;
  font-weight: 400;
  color: #939393;
}
.FamiliaMainHead > *:nth-child(3) .bottom,
.FamiliaMainHead > :nth-child(4) .bottom {
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
}
.FamiliaMainHead > *:nth-child(3) .bottom .button_wrapper,
.FamiliaRowBody .body .long_box .button_wrapper {
  display: flex;
}
.FamiliaMainHead > *:nth-child(3) .bottom .button_wrapper > * + *,
.FamiliaRowBody .body .long_box .button_wrapper > * + * {
  margin-left: 10px;
}
.FamiliaRowBody .body .long_box .button,
.FamiliaMainHead > *:nth-child(3) .button {
  padding: 0.2rem 1rem;
  border-radius: 99px;
  width: 150px;
  text-align: center;
  font-size: 16px;
}
.FamiliaMainHead > *:nth-child(3) .button.blue {
  background-color: #9febf7;
}
.FamiliaMainHead > *:nth-child(3) .button.yellow {
  background-color: #fce02c;
}
.FamiliaRowBody .body .long_box .button.blue {
  border: 2px solid #9febf7;
}
.FamiliaRowBody .body .long_box .button.yellow {
  border: 2px solid #fce02c;
}

.FamiliaRowBody .body > *:nth-child(3) {
  min-width: 45%;
}
.FamiliaRowBody .body > *:last-child,
.FamiliaRowBody .body > *:nth-child(4) {
  display: flex;
  align-items: center;
  padding-right: 0px;
  border: none;
  justify-content: space-between;
}
.FamiliaRowBody .body > *:last-child,
.FamiliaMainHead > :last-child {
  min-width: 100px;
}
.FamiliaRowBody .body > *:nth-child(4),
.FamiliaMainHead > :nth-child(4) {
  flex: 1;
  padding-right: 1rem;
}
.FamiliaRowBody .body > *:nth-child(4) {
  border-right: 1px solid #ddd;
}
.FamiliaRowBody .body > *:nth-child(4) button,
.FamiliaRowBody .body > *:nth-child(5) button {
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  padding: 0.3rem 1rem;
  font-size: 16px;
  border-radius: 99px;
}
.FamiliaRowBody .body > *:nth-child(5) button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.FamiliaRowBody .body > *:nth-child(5) button img {
  width: 15px;
}
.FamiliaRowBody .body > *:nth-child(4) button.yellow {
  background-color: #fce02c;
  color: #fff;
}
.FamiliaRowBody .body button {
  font-family: "Montserrat", sans-serif;
}
.FamiliaRowBody .body > *:nth-child(5) button.red {
  background-color: #ff5234;
  color: #fff;
}
.FamiliaRowBody .body > *:nth-child(5) button.red#outline {
  background-color: transparent;
  color: #ff5234;
  border: 1px solid #ff5234;
  justify-content: center;
}
.FamiliaRowBody .body > *:nth-child(5) button.red#outline img {
  display: none;
}

.sub_category_wrapper {
  display: flex;
  align-items: center;
  width: 34%;
  justify-content: space-between;
  border-left: 1px solid #ddd;
}
.bolan_catergory_wrapper {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  cursor: pointer;
}
.bolan_catergory_wrapper p {
  margin-left: 10px;
}
.sub_category_wrapper img:first-child {
  width: 25px;
}
@media screen and (max-width: 1530px) {
  .table_area {
    overflow: auto;
  }
  .FamiliaRowBody .body > *:nth-child(3) {
    /* min-width: 40%; */
  }
}
