.Login {
  height: 100vh;
  background: url(../../assets/img/auth/bg.png);
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: 0% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login .form_area > .logo_wrapper {
  display: flex;
  justify-content: center;
}
.Login .form_area > .logo_wrapper img {
  width: 250px;
  margin-top: 2rem;
}
.Login form {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fdfdfd;
  width: 550px;
  padding: 0rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}
.Login form .header_wrapper {
  padding: 1rem 0rem;
  border-bottom: 1px solid #ddd;
  width: 100%;
  text-align: center;
}
.Login form .header_wrapper img {
  width: 150px;
}

.Login form .body_wrapper {
  width: 100%;
  padding: 2rem 0rem;
}
.Login form .body_wrapper > p {
  text-align: center;
  font-size: 28px;
  margin-bottom: 3rem;
}
.Login form .body_wrapper > p span {
  font-weight: 600;
}
.Login form .body_wrapper > * + * {
  margin-top: 1rem;
}
.Login form .body_wrapper .input_wrapper {
  display: flex;
  align-items: center;
  height: 50px;

  padding: 0rem 0.5rem;
  border-bottom: 2px solid #ddd;
}
.Login form .body_wrapper .input_wrapper img {
  width: 30px;
}
.Login form .body_wrapper .input_wrapper input {
  width: 100%;
  background-color: transparent;
  border: none;
  height: 70%;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  border: none;
  font-weight: 600;
  outline: none;
}
.Login form .body_wrapper .button_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.Login form .body_wrapper .button_wrapper button {
  background: linear-gradient(45deg, #ffaa25, #ff5634);
  border: none;
  outline: none;
  padding: 0.8rem 2.5rem;
  border-radius: 10px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
  font-size: 22px;
  transition: 0.3s ease all;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.Login form .body_wrapper .button_wrapper button:hover {
  font-size: 23px;
}
.Login form .body_wrapper .link_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.Login form .body_wrapper .link_wrapper a {
  color: #000;
  font-size: 20px;
  border-bottom: 2px solid #ff5634;
  font-weight: 600;
  transition: 0.3s ease all;
}
.Login form .body_wrapper .link_wrapper a:hover {
  padding-bottom: 5px;
}
